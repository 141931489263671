export default {
  blogerName: 'PRINCE',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@PrinceInvest',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@princec0mpany',
    },
    {
      name: 'facebook',
      url: 'https://www.facebook.com/princeecompany/',
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/princecompany'
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/c92e3e32b',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/cbd4e3c1e',
      gameTitle: 'Royal High-Road',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c87d6946d',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/cf6d3ac1b',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c9cef9e52',
      gameTitle: 'Jet Air (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/cc8a2c54a',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/ce4fbc590',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
  ],
  headerText: 'Cadastre-se com um código promocional <strong>PRINCE</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'PRINCE',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
  attentionText: ''
}
